import { useLocation } from "@remix-run/react";
import type { ReactNode } from "react";
import { LoadingBar } from "./components/global/Loading/LoadingBar";
import { Sidebar } from "./components/global/Sidebar";

function Layout({ children }: { children: ReactNode }) {
  const { pathname } = useLocation();

  const isSidebarNeeded = !["/login", "/setup"].includes(pathname) && !pathname.startsWith("/auth");
  return (
    <>
      <LoadingBar />
      <div className="flex">
        {isSidebarNeeded && <Sidebar className="sticky top-0 hidden self-start md:block" />}
        <main className="max-w-full flex-1">{children}</main>
      </div>
    </>
  );
}

export default Layout;
