import { Text } from "@ui/components/Typography/Text";
import { cn } from "@ui/utils";
import { forwardRef } from "react";
import { BiFace, BiLogOut, BiSolidInbox } from "react-icons/bi";
type SidebarItemName = "inbox" | "logout" | "faq" | "templates" | "profile" | "prompt";

interface SidebarItemProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  name: SidebarItemName;
}

const SidebarItemNames = {
  inbox: "受信ボックス",
  profile: "プロフィール",
  logout: "ログアウト",
  faq: "FAQ",
  templates: "返信テンプレ",
  prompt: "AIプロンプト",
} as const satisfies Record<SidebarItemName, string>;

const size = "w-8 h-8";

export const SidebarItem = forwardRef<HTMLButtonElement, SidebarItemProps>(
  ({ className, name, ...props }, ref) => {
    return (
      <button
        type="button"
        className={cn("flex flex-col items-center gap-2 px-3 py-3 text-dark-foreground", className)}
        {...props}
        ref={ref}
      >
        {name === "profile" && <BiFace className={size} />}
        {name === "inbox" && <BiSolidInbox className={size} />}
        {name === "logout" && <BiLogOut className={size} />}
        <Text type="caption" size="sm">
          {SidebarItemNames[name]}
        </Text>
      </button>
    );
  }
);
