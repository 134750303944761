import { Link } from "@remix-run/react";
import { Logo } from "@ui/components/original/Logo";
import { cn } from "@ui/utils";
import { useAuth } from "~/hooks/useAuth";
import { SidebarItem } from "./SidebarItem";

interface SidebarProps {
  className?: string;
}

export function Sidebar({ className }: SidebarProps) {
  const { signOut } = useAuth();

  return (
    <nav
      className={cn(
        "flex min-h-screen flex-none flex-col bg-dark py-6 text-dark-foreground",
        className
      )}
    >
      <h1 className="flex justify-center px-4">
        <Link to="/">
          <Logo className="h-12 w-12" />
        </Link>
      </h1>
      <div className="flex flex-col py-4">
        <Link to="/tickets">
          <SidebarItem name="inbox" />
        </Link>
        {/* <SidebarItem name="inbox" />
        <SidebarItem name="inbox" /> */}
      </div>
      <hr className="border-secondary border-t" />
      <div className="flex flex-col py-4">
        <Link to="/profile">
          <SidebarItem name="profile" />
        </Link>
        <SidebarItem
          name="logout"
          onClick={() => {
            signOut();
          }}
        />
      </div>
    </nav>
  );
}
