import { useNavigation } from "@remix-run/react";
import { Progress } from "@ui/components/general/progress";
import { cn } from "@ui/utils";
import { useCallback, useEffect, useState } from "react";

export function LoadingBar() {
  const { state } = useNavigation();
  const [progress, setProgress] = useState(0);

  const setNextProgressTime = useCallback(() => {
    return window.setTimeout(() => {
      setProgress(progress + 10);
    }, 1000);
  }, [progress]);

  const startProgress = useCallback(() => {
    let t = window.setTimeout(() => {
      if (progress === 0) {
        // First time loading
        setProgress(25);
      }

      if (progress > 80) {
        return;
      }

      t = setNextProgressTime();
    }, 250);

    return () => clearTimeout(t);
  }, [progress, setNextProgressTime]);

  const endProgress = useCallback(() => {
    setProgress(100);
    setTimeout(() => {
      setProgress(0);
    }, 1250);
    return () => {};
  }, []);

  useEffect(() => {
    if (state !== "idle") {
      return startProgress();
    }

    if (0 < progress && progress < 100) {
      return endProgress();
    }
  }, [state, progress, startProgress, endProgress]);

  return (
    <Progress
      value={progress}
      className={cn(
        "fixed top-0 z-50 h-1 w-full rounded-none bg-white/70 opacity-100 transition-opacity",
        {
          "opacity-0": progress === 0,
        }
      )}
      indicatorClassName={cn("duration-1000", {
        "ease-in-out": progress === 100,
        "ease-out": progress === 100,
      })}
    />
  );
}
